.page--womens-wellness {
  @include media-query(small-palm) {
    text-align: center;
  }

  section:last-of-type {
    padding-bottom: 0;
  }

  section:nth-of-type(even) {
    padding-top: 0;
  }
}

.womens-wellness__partners {
  background-color: #e74a89;
  padding: em($spacing-unit--small);
  text-align: center;
}

.marketing-promo__img {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-bottom: em($spacing-unit);
}

.marketing__title {
  color: $green;
}
