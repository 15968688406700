.hero-banner {
  &,
  & + section,
  & + .section {
    margin-top: sb-rem(-$spacing-unit); // Keep section flush with hero
  }
}

$pages: (
  elysium-collection,
  home,
  last-minute,
  partners,
  womens-wellness
);

@each $page in $pages {
  .hero-banner--#{$page} {
    background-image: url("../images/hero/hero-#{$page}-mobile.jpg");

    @include media-query(palm-and-up) {
      background-image: url("../images/hero/hero-#{$page}.jpg");
    }
  }
}

.hero-banner--unavailable {
  background-color: $green;

  @include media-query("lap-and-up") {
    min-height: sb-rem(200px);
  }
}

.hero-banner--elysium-collection {
  @include media-query("mobile") {
    height: sb-rem(340px);
  }
}
