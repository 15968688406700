$flexgrid-spacing-sizes: (
  "tiny": $spacing-unit--tiny,
  "small": $spacing-unit--small,
  "large": $spacing-unit--large,
  "huge": $spacing-unit--huge,
  "flush": 0,
);

$flexgrid-alignment-values: (
  "top": "flex-start",
  "middle": "center",
  "bottom": "flex-end",
  "baseline": "baseline",
  "stretch": "stretch",
);

$flexgrid-justify-values: (
  "left": "flex-start",
  "center": "center",
  "right": "flex-end",
  "between": "space-between",
  "around": "space-around",
);

.layout {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 0;
  margin-left: -$spacing-unit;

  > .layout__item {
    padding-left: $spacing-unit;
  }
}

.layout--rev {
  flex-direction: row-reverse;
}

.layout--nowrap {
  flex-wrap: nowrap;
}

@each $modifier, $size in $flexgrid-spacing-sizes {
  .layout--#{$modifier} {
    margin-left: -$size;

    > .layout__item {
      padding-left: $size;
    }
  }
}

.layout__item {
  flex: 0 1 auto;
  width: 100%;
}

@each $modifier, $value in $flexgrid-justify-values {
  .layout--#{$modifier} {
    justify-content: #{$value};
  }
}

@each $modifier, $value in $flexgrid-alignment-values {
  .layout--#{$modifier} {
    align-items: #{$value};
  }

  .layout__item--#{$modifier} {
    align-self: #{$value};
  }
}
